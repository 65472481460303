<template>
  <CaseList
    propTitle="新增案件"
    :propAllowSearch="allowSearch"
    :propCaseMode="case_mode"
  ></CaseList>
</template>
<script>
import { case_mode_enum } from "@/case.js";
import CaseList from "./CaseList.vue";
export default {
  name: "case-draft",
  components: {
    CaseList,
  },
  data: (vm) => ({
    case_mode_enum: case_mode_enum,
    allowSearch: true,
    case_mode: case_mode_enum.Draft,
  }),
};
</script>
